import "./home.css";
import { Typography } from "@mui/material";
// import YoutubeCard from "../YoutubeCard/YoutubeCard";
// import { Link } from "react-router-dom";
import { MouseOutlined } from "@mui/icons-material";
import { Cursor, useTypewriter } from "react-simple-typewriter";
// import About from "../About/About";
import { motion } from "framer-motion";
import SkillCube from "../SkillCube/SkillCube";
import StuffCard from "../StuffCard/StuffCard";
// import Carousel from "../Carousel/Carousel";

const Home = ({ skills, about, projects }) => {
  const [text, count] = useTypewriter({
    words: [
      "<Full-Stack Developer />",
      "Software_Engineer.py",
      "' it's great to see you here :) '",
    ],
    loop: true,
    delaySpeed: 2000,
  });

  const projectsReversed = [...projects].reverse();

  return (
    <div className="home">
      <div className="homePage" id="home">
        <canvas className="homeCanvas"></canvas>

        <div className="homeCanvasContainer">
          <Typography variant="h1">
            <div className="firstName">
              <p>Y</p>
              <p>I</p>
              <p>M</p>
              <p>I</p>
              <p>N</p>
              <p>G</p>
            </div>

            <div className="lastName">
              <p>L</p>
              <p>I</p>
            </div>
          </Typography>

          <Typography variant="h5">
            <span>{text}</span>
            <Cursor cursorColor="white" />
          </Typography>
        </div>

        <div className="homeScrollBtn">
          <MouseOutlined />
        </div>
      </div>

      <div className="homeAbout" id="about">
        <motion.img
          initial={{ x: -100, opacity: 0 }}
          transition={{ duration: 1 }}
          whileInView={{ opacity: 1, x: 0 }}
          // viewport={{ once: true }}
          src={about.avatar.url}
          alt="Doge"
          className="homeAboutAvatar"
        />
        <motion.div
          className="homeAboutText"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Typography variant="h4">About Me</Typography>
          <Typography>{about.description}</Typography>
        </motion.div>
      </div>

      <div className="homeSkills" id="skills">
        <motion.div
          className="homeSkillsText"
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
          whileInView={{ opacity: 1 }}
        >
          <Typography variant="h4">My Skills</Typography>
        </motion.div>

        <motion.div
          className="cubeContainer"
          initial={{ x: 200, opacity: 0 }}
          transition={{ duration: 1 }}
          whileInView={{ opacity: 1, x: 0 }}
        >
          <SkillCube
            img1={skills.image1.url}
            img2={skills.image2.url}
            img3={skills.image3.url}
            img4={skills.image4.url}
            img5={skills.image5.url}
            img6={skills.image6.url}
          />
          <SkillCube
            img1={skills.image7.url}
            img2={skills.image8.url}
            img3={skills.image9.url}
            img4={skills.image10.url}
            img5={skills.image11.url}
            img6={skills.image12.url}
          />
          <SkillCube
            img1={skills.image13.url}
            img2={skills.image14.url}
            img3={skills.image15.url}
            img4={skills.image16.url}
            img5={skills.image17.url}
            img6={skills.image18.url}
          />
          <SkillCube
            img1={skills.image19.url}
            img2={skills.image20.url}
            img3={skills.image21.url}
            img4={skills.image22.url}
            img5={skills.image23.url}
            img6={skills.image24.url}
          />
        </motion.div>

        <motion.div
          className="homeSkillsBottom"
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
          whileInView={{ opacity: 1 }}
        >
          <Typography>I do a little bit of everything!</Typography>
        </motion.div>
      </div>

      <div className="homeProject" id="projects">
        <motion.div
          className="homeProjectText"
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
          whileInView={{ opacity: 1 }}
        >
          <Typography variant="h4">My Work</Typography>
        </motion.div>
        <motion.div
          className="homeProjectWrapper"
          initial={{ y: 150, opacity: 0 }}
          transition={{ duration: 1 }}
          whileInView={{ opacity: 1, y: 0 }}
        >
          {projectsReversed.map((item) => (
            <StuffCard
              image={item.image.url}
              title={item.title}
              url={item.url}
              id={item._id}
              key={item._id}
              techStack={item.techStack}
              description={item.description}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Home;
