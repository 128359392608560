import React, { useEffect, useState } from "react";
import "./adminPanel.css";
import { Button, Typography } from "@mui/material";
import { AiOutlineProject } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";
import { MdTimeline } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateUser } from "../../actions/user";
// import { useAlert } from "react-alert";

const AdminPanel = () => {
  const dispatch = useDispatch();
  // const alert = useAlert();

  const { message: loginMessage } = useSelector((state) => state.login);
  const { message, error, loading } = useSelector((state) => state.update);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [skills, setSkills] = useState({});
  const [about, setAbout] = useState({});

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUser(name, email, password, skills, about));

    console.log("name: ", name);
    console.log("email: ", email);
    console.log("password: ", password);
    console.log("skills: ", skills);
    console.log("about: ", about);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  const handleAboutImage = (e) => {
    const file = e.target.files[0];
    const Reader = new FileReader();

    Reader.readAsDataURL(file);

    Reader.onload = () => {
      if (Reader.readyState === 2) {
        setAbout({ ...about, avatar: Reader.result });
      }
    };
  };

  const handleImages = (e, val) => {
    const file = e.target.files[0];
    const Reader = new FileReader();

    Reader.readAsDataURL(file);

    Reader.onload = () => {
      if (Reader.readyState === 2) {
        if (val === 1) {
          setSkills({ ...skills, image1: Reader.result });
        }
        if (val === 2) {
          setSkills({ ...skills, image2: Reader.result });
        }
        if (val === 3) {
          setSkills({ ...skills, image3: Reader.result });
        }
        if (val === 4) {
          setSkills({ ...skills, image4: Reader.result });
        }
        if (val === 5) {
          setSkills({ ...skills, image5: Reader.result });
        }
        if (val === 6) {
          setSkills({ ...skills, image6: Reader.result });
        }
        if (val === 7) {
          setSkills({ ...skills, image7: Reader.result });
        }
        if (val === 8) {
          setSkills({ ...skills, image8: Reader.result });
        }
        if (val === 9) {
          setSkills({ ...skills, image9: Reader.result });
        }
        if (val === 10) {
          setSkills({ ...skills, image10: Reader.result });
        }
        if (val === 11) {
          setSkills({ ...skills, image11: Reader.result });
        }
        if (val === 12) {
          setSkills({ ...skills, image12: Reader.result });
        }
        if (val === 13) {
          setSkills({ ...skills, image13: Reader.result });
        }
        if (val === 14) {
          setSkills({ ...skills, image14: Reader.result });
        }
        if (val === 15) {
          setSkills({ ...skills, image15: Reader.result });
        }
        if (val === 16) {
          setSkills({ ...skills, image16: Reader.result });
        }
        if (val === 17) {
          setSkills({ ...skills, image17: Reader.result });
        }
        if (val === 18) {
          setSkills({ ...skills, image18: Reader.result });
        }
        if (val === 19) {
          setSkills({ ...skills, image19: Reader.result });
        }
        if (val === 20) {
          setSkills({ ...skills, image20: Reader.result });
        }
        if (val === 21) {
          setSkills({ ...skills, image21: Reader.result });
        }
        if (val === 22) {
          setSkills({ ...skills, image22: Reader.result });
        }
        if (val === 23) {
          setSkills({ ...skills, image23: Reader.result });
        }
        if (val === 24) {
          setSkills({ ...skills, image24: Reader.result });
        }
      }
    };
  };

  useEffect(() => {
    if (error) {
      // alert.error(error);
      alert(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    if (message) {
      // alert.success(message);
      alert(message);
      dispatch({ type: "CLEAR_MESSAGE" });
    }
    if (loginMessage) {
      // alert.success(loginMessage);
      alert(loginMessage);
      dispatch({ type: "CLEAR_MESSAGE" });
    }
  }, [error, message, dispatch, loginMessage]);

  return (
    <div className="adminPanel">
      <div className="adminPanelContainer">
        <Typography variant="h4">
          <p>A</p>
          <p>D</p>
          <p>M</p>
          <p>I</p>
          <p style={{ marginRight: "1vmax" }}>N</p>

          <p>P</p>
          <p>A</p>
          <p>N</p>
          <p>E</p>
          <p>L</p>
        </Typography>

        <form onSubmit={submitHandler}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="adminPanelInputs"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="adminPanelInputs"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="adminPanelInputs"
          />

          <div className="adminPanelSkill">
            <div>
              <Typography>SKILL 1</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 1)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 2</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 2)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 3</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 3)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 4</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 4)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 5</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 5)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 6</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 6)}
                accept="image/*"
              />
            </div>
            <div className="borderLine"></div>
            <div>
              <Typography>SKILL 7</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 7)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 8</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 8)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 9</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 9)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 10</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 10)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 11</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 11)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 12</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 12)}
                accept="image/*"
              />
            </div>
            <div className="borderLine"></div>
            <div>
              <Typography>SKILL 13</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 13)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 14</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 14)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 15</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 15)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 16</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 16)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 17</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 17)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 18</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 18)}
                accept="image/*"
              />
            </div>
            <div className="borderLine"></div>
            <div>
              <Typography>SKILL 19</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 19)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 20</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 20)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 21</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 21)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 22</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 22)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 23</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 23)}
                accept="image/*"
              />
            </div>
            <div>
              <Typography>SKILL 24</Typography>
              <input
                className="adminPanelFileUpload"
                type="file"
                onChange={(e) => handleImages(e, 24)}
                accept="image/*"
              />
            </div>
          </div>

          <div className="adminPanelAbout">
            <fieldset>
              <legend>About</legend>
              <input
                type="text"
                placeholder="Name"
                value={about.name}
                onChange={(e) => setAbout({ ...about, name: e.target.value })}
                className="adminPanelInputs"
              />
              <input
                type="text"
                placeholder="Title"
                value={about.title}
                onChange={(e) => setAbout({ ...about, title: e.target.value })}
                className="adminPanelInputs"
              />
              <input
                type="text"
                placeholder="Subtitle"
                value={about.subtitle}
                onChange={(e) =>
                  setAbout({ ...about, subtitle: e.target.value })
                }
                className="adminPanelInputs"
              />
              <input
                type="text"
                placeholder="Description"
                value={about.description}
                onChange={(e) =>
                  setAbout({ ...about, description: e.target.value })
                }
                className="adminPanelInputs"
              />
              <input
                type="text"
                placeholder="Quote"
                value={about.quote}
                onChange={(e) => setAbout({ ...about, quote: e.target.value })}
                className="adminPanelInputs"
              />

              <input
                type="file"
                onChange={handleAboutImage}
                className="adminPanelFileUpload"
                placeholder="Choose Avatar"
                accept="image/*"
              />
            </fieldset>
          </div>

          <Link to="/admin/timeline">
            TIMELINE <MdTimeline />
          </Link>
          <Link to="/admin/youtube">
            YOUTUBE <FaYoutube />
          </Link>
          <Link to="/admin/project">
            PROJECTS <AiOutlineProject />
          </Link>

          <Button type="submit" variant="contained" disabled={loading}>
            Update
          </Button>
        </form>

        <Button
          variant="contained"
          color="error"
          style={{ display: "block", margin: "4vmax auto" }}
          onClick={logoutHandler}
        >
          LOGOUT
        </Button>
      </div>
    </div>
  );
};

export default AdminPanel;
