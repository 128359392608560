import React from "react";
import "./stuffCard.css";
import { Button, Typography } from "@mui/material";
import { FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { deleteYoutube, getUser } from "../../actions/user";

const StuffCard = ({
  url = "https://youtube.com/6packprogrammer",
  title = "Title Here",
  image,
  isAdmin = false,
  id,
  techStack,
  description,
}) => {
  const dispatch = useDispatch();

  const deleteHandler = async (id) => {
    await dispatch(deleteYoutube(id));
    dispatch(getUser());
  };

  return (
    <div className="stuffCard">
      <a href={url} target="blank">
        <img src={image} alt="Video" />
        <Typography variant="h6">{title}</Typography>
        <div className="techStack">
          {techStack.split(", ").map((tech, index) => (
            <span className="tech" key={index}>
              {tech}
            </span>
          ))}
          {/* <span className="tech">JavaScript</span>
          <span className="tech">HTML</span>
          <span className="tech">CSS</span> */}
        </div>
        <Typography>{description}</Typography>
      </a>
      {isAdmin && (
        <Button
          style={{
            margin: "auto",
            display: "block",
            color: "rgba(40,40,40,0.7)",
          }}
          onClick={() => deleteHandler(id)}
        >
          <FaTrash />
        </Button>
      )}
    </div>
  );
};

export default StuffCard;
