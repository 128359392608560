import { Button, Typography } from "@mui/material";
import React from "react";
import { FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { deleteYoutube, getUser } from "../../actions/user";
import "./youtubeCard.css";

const YoutubeCard = ({
  url = "https://youtube.com/6packprogrammer",
  title = "Title Here",
  image,
  isAdmin = false,
  id,
}) => {
  const dispatch = useDispatch();

  const deleteHandler = async (id) => {
    await dispatch(deleteYoutube(id));
    dispatch(getUser());
  };
  return (
    <div className="youtubeCard">
      <a href={url} target="blank">
        <img src={image} alt="Video" />
        <Typography variant="h6">{title}</Typography>
        <div>
          <span>JavaScript</span>
          <span>HTML</span>
          <span>CSS</span>
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
          harum tenetur ad suscipit porro voluptate pariatur odit rem debitis
          asperiores molestias temporibus libero nostrum aliquid sint eos,
          officia earum incidunt? Lorem ipsum dolor sit, amet consectetur
          adipisicing elit. Reprehenderit officiis illo quis, minus quasi quo
          velit aliquid enim quia odit quam, voluptatibus officia rerum facilis,
          commodi distinctio. Architecto, expedita enim?
        </p>
      </a>
      {isAdmin && (
        <Button
          style={{
            margin: "auto",
            display: "block",
            color: "rgba(40,40,40,0.7)",
          }}
          onClick={() => deleteHandler(id)}
        >
          <FaTrash />
        </Button>
      )}
    </div>
  );
};

export default YoutubeCard;
