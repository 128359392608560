import React from "react";
import "./skillCube.css";

const SkillCube = ({ img1, img2, img3, img4, img5, img6 }) => {
  return (
    <div className="homeCubeInstance">
      <div className="homeCubeSkills">
        <div className="homeCubeSkillsFaces homeCubeSkillsFace1">
          <img src={img1} alt="Face1" />
        </div>

        <div className="homeCubeSkillsFaces homeCubeSkillsFace2">
          <img src={img2} alt="Face2" />
        </div>

        <div className="homeCubeSkillsFaces homeCubeSkillsFace3">
          <img src={img3} alt="Face3" />
        </div>

        <div className="homeCubeSkillsFaces homeCubeSkillsFace4">
          <img src={img4} alt="Face4" />
        </div>

        <div className="homeCubeSkillsFaces homeCubeSkillsFace5">
          <img src={img5} alt="Face5" />
        </div>

        <div className="homeCubeSkillsFaces homeCubeSkillsFace6">
          <img src={img6} alt="Face6" />
        </div>
      </div>

      {/* <div className="cubeShadow"></div> */}
    </div>
  );
};

export default SkillCube;
