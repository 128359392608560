import React, { useState } from "react";
import "./nav.css";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiBook, BiMessageSquareDetail } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { Link } from "react-scroll";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <div className="nav">
      <Link to="home" spy={true} smooth={true} duration={500}>
        <AiOutlineHome />
      </Link>
      <Link to="about" spy={true} smooth={true} duration={500}>
        <AiOutlineUser />
      </Link>
      <Link to="skills" spy={true} smooth={true} duration={500}>
        <BiBook />
      </Link>
      <Link to="projects" spy={true} smooth={true} duration={500}>
        <RiServiceLine />
      </Link>
      {/* <Link to="contact" spy={true} smooth={true} duration={500}>
        <BiMessageSquareDetail />
      </Link> */}
    </div>
  );
};

export default Nav;
