import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { BsGithub, BsLinkedin, BsHouseDoorFill } from "react-icons/bs";
import { motion } from "framer-motion";

// const Footer = () => {
//   return (
//     <div className="footer">
//       <div>
//         <Typography variant="h5">Status</Typography>
//         <Typography>
//           Actively looking for the next opportunity to shine!
//         </Typography>

//         <Link to="/contact" className="footerContactBtn">
//           <Typography>Contact Me!</Typography>
//         </Link>
//       </div>

//       <div>
//         <Typography variant="h6">Social Media</Typography>
//         <a href="https://github.com/meabhisingh/" target="black">
//           <BsGithub />
//         </a>
//         <a href="https://www.linkedin.com/in/meabhisingh" target="black">
//           <BsLinkedin />
//         </a>
//       </div>
//     </div>
//   );
// };

const Footer = () => {
  return (
    <div className="footer" id="contact">
      <motion.div
        className="footerContact"
        initial={{ x: -100, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
      >
        <Typography variant="h5">Contact</Typography>
        <Typography>Name: Yiming Li</Typography>
        <Typography>Phone: +1 &#40;860&#41; 221-4670</Typography>
        <Typography>Email: billyli@umich.edu</Typography>

        {/* <Link to="/contact" className="footerContactBtn">
          <Typography>Contact Me!</Typography>
        </Link> */}
      </motion.div>

      <motion.div
        className="media"
        initial={{ x: 100, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
      >
        {/* <Typography variant="h6">Social Media</Typography> */}
        <Typography variant="h5">Media</Typography>
        <div className="icons">
          <a href="https://github.com/YimingLi-Billy" target="black">
            <BsGithub />
          </a>
          <a href="https://www.linkedin.com/in/yimingli1998/" target="black">
            <BsLinkedin />
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default Footer;
